import React from "react"
import Layout from "../components/layout"
import WI from "../components/workimage"
import ReviewSlider from "../components/reviewSlider"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ServiceMed from "../svg/medical.svg"
import ServiceWash from "../svg/wash.svg"
import ServiceTeeth from "../svg/teeth.svg"
import ServiceCut from "../svg/scissor-and-comb.svg"
import Dropdown from "../components/dropdown"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      servicesImage: file(relativePath: { eq: "services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      aboutImage: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviewImage: file(relativePath: { eq: "review.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert1: file(relativePath: { eq: "certs/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert2: file(relativePath: { eq: "certs/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert3: file(relativePath: { eq: "certs/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert4: file(relativePath: { eq: "certs/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert5: file(relativePath: { eq: "certs/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert6: file(relativePath: { eq: "certs/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert7: file(relativePath: { eq: "certs/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert8: file(relativePath: { eq: "certs/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert9: file(relativePath: { eq: "certs/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert10: file(relativePath: { eq: "certs/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work1a: file(relativePath: { eq: "works/1a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work2a: file(relativePath: { eq: "works/2a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work3a: file(relativePath: { eq: "works/3a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work1: file(relativePath: { eq: "works/1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work2: file(relativePath: { eq: "works/2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work3: file(relativePath: { eq: "works/3.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work4a: file(relativePath: { eq: "works/4a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work4: file(relativePath: { eq: "works/4.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work6a: file(relativePath: { eq: "works/6a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work6: file(relativePath: { eq: "works/6.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work5a: file(relativePath: { eq: "works/5a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work5: file(relativePath: { eq: "works/5.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work7a: file(relativePath: { eq: "works/7a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work7: file(relativePath: { eq: "works/7.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work8a: file(relativePath: { eq: "works/8a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work8: file(relativePath: { eq: "works/8.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client1: file(relativePath: { eq: "clients/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Профессиональный груминг в Витебске" />
      <section className="hero">
        <div className="hero-image">
          <Img fluid={data.heroImage.childImageSharp.fluid} />
        </div>
        <div className="wrap">
          <div>
            <h1
              data-sal="slide-left"
              data-sal-delay="10"
              data-sal-easing="ease-out"
              data-sal-duration="500ms">
              Сделай <br />
              <span>питомца</span>
              <br /> счастливым
            </h1>
            <div
              data-sal="slide-left"
              data-sal-delay="10"
              data-sal-easing="ease-out"
              data-sal-duration="500ms">
              <h2>
                Груминг домашних<br />
                животных в Витебске.
              </h2>
              <pre>Люби,<br />                      оберегай,<br />                                                 не бросай!</pre>
              <p>Мы в ответе за тех кого приручили...</p>
            </div>
            <form action="/dofeedback.php" method="POST"
              data-sal="fade"
              data-sal-delay="10"
              data-sal-easing="ease-out"
              data-sal-duration="500ms">
              <input
                className="input"
                type="text"
                placeholder="Имя"
                name="name"
              ></input>
              <br />
              <input
                className="input"
                type="tel"
                placeholder="Телефон"
                name="phone"
              ></input>
              <br />
              <button className="button-paw">Оставить заявку</button>
            </form>
          </div>
        </div>
      </section>
      <section className="services" id="services">
        <h3
          className="centered-heading"
          data-sal="slide-up"
          data-sal-delay="10"
          data-sal-easing="ease-out"
          data-sal-duration="500ms"
        >
          Наши услуги
          <p>Мы качественно позаботимся о вашем питомце</p>
        </h3>
        <div className="wrap">
          <Img
            className="services-image"
            fluid={data.servicesImage.childImageSharp.fluid}
          />
          <div
            className="services-item"
            data-sal="slide-right"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms"
          >
            <div className="service-icon">
              <ServiceCut />
            </div>
            <h4>Стрижка</h4>
            <p>
              Мы осуществляем: выбривание шерсти между подушечками лап, стрижку
              интимных мест, стрижку зоны глаз, выщипывание шерсти из ушей
            </p>
          </div>
          <div
            className="services-item"
            data-sal="slide-left"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms"
          >
            <div className="service-icon">
              <ServiceWash />
            </div>
            <h4>Купание</h4>
            <p>
              Мытье профессиональными шампунями в зависимости от типа шерсти с
              кондиционером, сушка и укладка с применением увлажняющего спрея.
            </p>
          </div>
          <div
            className="services-item"
            data-sal="slide-right"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms"
          >
            <div className="service-icon">
              <ServiceMed />
            </div>
            <h4>Гигиенические процедуры</h4>
            <p>
              Чистим глаза специальным средством, вычёсываем шерсть, подрезаем
              когти и осуществляем чистку параанальной железы.
            </p>
          </div>
          <div
            className="services-item"
            data-sal="slide-left"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms"
          >
            <div className="service-icon">
              <ServiceTeeth />
            </div>
            <h4>Чистка зубов ультразвуком</h4>
            <p>
              Существенное отличие чистки зубов у нас, от чистки зубов у других
              мастеров является в том, что мы делаем это <b>без наркоза</b>!
            </p>
          </div>
        </div>
      </section>
      <section className="packages">
        <h3
          className="centered-heading"
          data-sal="slide-up"
          data-sal-delay="10"
          data-sal-easing="ease-out"
          data-sal-duration="500ms"
        >
          Комплекты услуг
          <p>
            Приём осуществляется клинически здоровых и провакцинированных
            животных
          </p>
        </h3>
        <div className="wrap">
          <div >
            <div className="card">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a> Полный комплекс </a>
                </h4>
              </div>
              <div className="panel-collapse">
                <div className="panel-body">
                  <p>
                    Комплексный уход включает множество гигиенических процедур
                    направленных на придание вашему питомцу ухоженного вида.
                  </p>
                  <br></br>
                  <Dropdown heading="Что входит в комплекс?">
                    <ul>
                      <li>Подстригание когтей</li>
                      <li>
                        Выщипывание волос в ушной раковине, чистка ушек лосьоном
                      </li>
                      <li>Уход за глазами</li>
                      <li>
                        Мытье профессиональными шампунями в зависимости от типа
                        шерсти с кондиционером , сушка и укладка с применением
                        увлажняющего спрея
                      </li>
                      <li>
                        Вычесывание шерсти (вычесывание колтунов оплачивается
                        отдельно)
                      </li>
                      <li>Стрижка</li>
                      <li>
                        Нанесение пасты Оразим для размягчения зубного налёта
                      </li>
                      <li>Чистка параанальной железы</li>
                    </ul>
                    <i>
                      Стоимость может увеличиваться на усмотрение грумера при
                      наличие множественных колтунов или агрессивного поведения
                      питомца!
                    </i>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="card">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a>Гигиеническая стрижка</a>
                </h4>
              </div>
              <div className="panel-collapse">
                <div className="panel-body">
                  <p>
                    Гигиеническая стрижка — особый вид ухода, направленный на
                    обновление шерсти. Он подойдет породам, которые утратили
                    способность линять самостоятельно.
                  </p>
                  <br></br>
                  <Dropdown heading="Что входит в комплекс?">
                    <ul>
                      <li>подрезание когтей</li>
                      <li>
                        выщипывание шерсти из ушей и чистка их гигиеническим
                        лосьоном
                      </li>
                      <li>чистка глаз (гигиеническим лосьоном)</li>
                      <li>выбривание шерсти между подушечками лап</li>
                      <li>стрижка интимных мест (попа, пах, подмышки)</li>
                      <li>стрижка зоны возле глаз</li>
                      <li>купание (шампунем, бальзамом)</li>
                      <li>сушка, вычёсывание, укладка шерсти</li>
                    </ul>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="card">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a> Чистка зубов ультразвуком</a>
                </h4>
              </div>
              <div className="panel-collapse">
                <div className="panel-body">
                  <p>
                    Неправильное питание с недостатком твердых элементов,
                    недостаточно тщательный уход за зубами собаки приводят к
                    стоматологическим проблемам. В этих случаях помогает
                    ультразвуковая чистка зубов.
                  </p>
                  <br />
                  <Dropdown heading="Что входит в комплекс?">
                    <ul>
                      <li>Ультразвуковая чистка зубов</li>
                    </ul>
                    <i>
                      Важной моментом является то, что процедура производится
                      без наркоза.
                    </i>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about" id="about">
        <div className="eq-wrap wrap">
          <div
            data-sal="fade"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms" className="eq-wrap--image">
            <div className="eq-wrap--image_blur">
              <Img fluid={data.aboutImage.childImageSharp.fluid} />
            </div>
            <div className="eq-wrap--image_normal">
              <Img fluid={data.aboutImage.childImageSharp.fluid} />
            </div>
          </div>
          <div
            data-sal="slide-left"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms" className="eq-wrap--image" className="eq-wrap--text">
            <h3>
              Немного о нас <p>Эксперт по уходу за вашими любимцами</p>
            </h3>
            <p>
              Ваш питомец в руках специалиста с ветеринарным образованием,
              прошедшим дополнительное обучение в академии груминга «Боншери»
              (Москва). В своей работе мы используем только качественный
              стерильный инструмент и профессиональную косметику.
            </p>
            <p>
              Мы знаем как найти подход к вашим любимым питомцам, чтобы они
              чувствовали себя комфортно и в безопасности. Стрижка без наркоза!
              Придадим вашему любимцу неповторимый образ без царапин и порезов,
              окружим его заботой и лаской на протяжении всей процедуры.
            </p>
            <Link className="button-paw" to="/#feedback">
              Оставить заявку
            </Link>
          </div>
        </div>
      </section>
      <section className="certs">
        <h3
          className="centered-heading"
          data-sal="slide-up"
          data-sal-delay="10"
          data-sal-easing="ease-out"
          data-sal-duration="500ms"
        >
          Сертификаты
          <p>Подтверждение того что вы доверяетесь профессионалу.</p>
        </h3>
        <div 
          data-sal="fade"
          data-sal-delay="100"
          data-sal-easing="ease-out"
          data-sal-duration="700ms"  className="marquee">
          <div className="marquee-wrap">
            <Img fluid={data.cert1.childImageSharp.fluid} />
            <Img fluid={data.cert2.childImageSharp.fluid} />
            <Img fluid={data.cert3.childImageSharp.fluid} />
            <Img fluid={data.cert4.childImageSharp.fluid} />
            <Img fluid={data.cert5.childImageSharp.fluid} />
            <Img fluid={data.cert6.childImageSharp.fluid} />
            <Img fluid={data.cert7.childImageSharp.fluid} />
            <Img fluid={data.cert8.childImageSharp.fluid} />
            <Img fluid={data.cert9.childImageSharp.fluid} />
            <Img fluid={data.cert10.childImageSharp.fluid} />
            <Img fluid={data.cert1.childImageSharp.fluid} />
            <Img fluid={data.cert2.childImageSharp.fluid} />
            <Img fluid={data.cert3.childImageSharp.fluid} />
            <Img fluid={data.cert4.childImageSharp.fluid} />
            <Img fluid={data.cert5.childImageSharp.fluid} />
            <Img fluid={data.cert6.childImageSharp.fluid} />
            <Img fluid={data.cert7.childImageSharp.fluid} />
            <Img fluid={data.cert8.childImageSharp.fluid} />
            <Img fluid={data.cert9.childImageSharp.fluid} />
            <Img fluid={data.cert10.childImageSharp.fluid} />
          </div>
        </div>
      </section>
      <section className="portfolio" id="works">
        <h3
          className="centered-heading"
          data-sal="slide-up"
          data-sal-delay="10"
          data-sal-easing="ease-out"
          data-sal-duration="500ms"
        >
          Наши работы
          <p>Животные которым мы помогли</p>
        </h3>
        <div className="portfolio-grid">
          <div>
            <WI
              after={data.work1a.childImageSharp.fluid}
              before={data.work1.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work2a.childImageSharp.fluid}
              before={data.work2.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work3a.childImageSharp.fluid}
              before={data.work3.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work4a.childImageSharp.fluid}
              before={data.work4.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work5a.childImageSharp.fluid}
              before={data.work5.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work6a.childImageSharp.fluid}
              before={data.work6.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work7a.childImageSharp.fluid}
              before={data.work7.childImageSharp.fluid}
            />
          </div>
          <div>
            <WI
              after={data.work8a.childImageSharp.fluid}
              before={data.work8.childImageSharp.fluid}
            />
          </div>
        </div>
        <Swiper navigation direction="horizontal" pagination slidesPerView={1}>
          <SwiperSlide>
            <WI
              after={data.work1a.childImageSharp.fluid}
              before={data.work1.childImageSharp.fluid}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.work2a.childImageSharp.fluid}></Img>
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.work3a.childImageSharp.fluid}></Img>
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.work4a.childImageSharp.fluid}></Img>
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.work5a.childImageSharp.fluid}></Img>
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.work6a.childImageSharp.fluid}></Img>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="review" id="reviews">
        <div className="eq-wrap wrap">
          <div
            data-sal="slide-up"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms" className="eq-wrap--image">
            <div className="eq-wrap--image_blur">
              <Img fluid={data.reviewImage.childImageSharp.fluid} />
            </div>
            <div className="eq-wrap--image_normal">
              <Img fluid={data.reviewImage.childImageSharp.fluid} />
            </div>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="10"
            data-sal-easing="ease-out"
            data-sal-duration="500ms" className="eq-wrap--text">
            <ReviewSlider />
          </div>
        </div>
      </section>
      <section className="callme">
        <div 
          data-sal="fade"
          data-sal-delay="10"
          data-sal-easing="ease-out"
          data-sal-duration="500ms" className="wrap">
          <h3>Остались вопросы?</h3>
          <p>Оставьте ваш номер и мы перезвоним вам в течение 1 минуты.</p>
          <form action="/dofeedback.php" method="POST" className="callme_form">
            <input
              className="input"
              type="tel"
              placeholder="Телефон"
              name="phone"
            ></input>
            <button className="button-paw">Оставить заявку</button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
